<template>
  <v-container fluid>
    <v-sheet :width="$vuetify.breakpoint.mobile ? '92vw' : '80vw'" class="mx-auto">
      <v-container fluid class="navbar d-flex flex-row">
        <v-row class="align-center" no-gutters>
          <v-col cols="auto">
            <div class="text-h6 font-weight-regular">Køreskoler</div>
          </v-col>
          <v-col cols="4" class="mx-auto">
            <div>
              <v-text-field v-model="search" append-icon="mdi-magnify" clearable label="Search" single-line outlined dense hide-details=""></v-text-field>
            </div>
          </v-col>
          <v-col cols="auto">
            <r-btn class="align-center" :action="true" @click="addOrganization"> Tilføj Køreskole</r-btn>
          </v-col>
        </v-row>
      </v-container>
      <v-data-table :items="organizationList" :search="search" :headers="dataTableHeaders" @click:row="organizationDetails" v-bind="$util.dataTableOptions()">
        <template #[`item.disabled`]="{ item }">
          <readonly-checkbox :value="!item.disabled" />
        </template>
        <template #[`item.actions`]="{ item }" class="d-flex">
          <div class="d-flex justify-end">
            <r-btn icon title="Rediger" @click.stop="editOrganization(item)">
              <v-icon>mdi-pencil</v-icon>
            </r-btn>
            <r-btn icon @click.stop="organizationDetails(item)" title="Detaljer">
              <v-icon>mdi-dots-horizontal</v-icon>
            </r-btn>
          </div>
        </template>
      </v-data-table>

      <create-edit-organization ref="CreateEditOrganizationRef" @organization-updated="getOrganizationList"></create-edit-organization>
    </v-sheet>
  </v-container>
</template>

<script>
import apiService from '@/services/apiService';
import ReadonlyCheckbox from '@/components/ReadonlyCheckbox.vue';
import CreateEditOrganization from '@/components/dialogs/CreateEditOrganization.vue';
import { mapGetters } from 'vuex';

export default {
  components: { ReadonlyCheckbox, CreateEditOrganization },
  data: () => ({
    organizationList: [],
    search: '',

    dataTableHeaders: [
      {
        text: 'Navn',
        value: 'name',
      },
      {
        text: 'CVR',
        value: 'cvr',
      },
      {
        text: 'Tlf. Nummer',
        value: 'phone',
      },
      {
        text: '',
        value: 'actions',
      },
    ],
  }),
  methods: {
    async getOrganizationList() {
      if (this.isAdmin) {
        this.organizationList = await apiService.getAdminOrganizationList();
      } else {
        this.organizationList = await apiService.getOrganizationList();
        if (this.organizationList.length == 1) {
          this.organizationDetails(this.organizationList[0]);
        }
      }
    },
    addOrganization() {
      this.$refs.CreateEditOrganizationRef.createOrganization();
    },
    editOrganization(Organization) {
      this.$refs.CreateEditOrganizationRef.editOrganization(Organization);
    },
    organizationDetails(org) {
      this.$router.push({ name: 'orgDetails', params: { OrgId: org.id } });
    },
  },
  computed: {
    ...mapGetters(['isAdmin']),
    detailsView() {
      return this.$route.params.Id;
    },
  },
  created() {
    this.getOrganizationList();
  },
  mounted() {},
};
</script>

<style>
</style>